<template>
  <div class="index-container">
    <div class="bread"> <span @click="home()">操作台 / </span><span class="gray">租车</span></div>
    <el-row class="table-body">
      <div class="line clearfix">
        <h5 class="fl title">租车</h5>
        <div class="fr search">
          <el-input size="small" type="text" placeholder="请输入车牌号"   v-model="keywords">
            <template slot="prefix">
              <el-button icon="el-icon-search" type="text" disabled :model="head"></el-button>
            </template>
          </el-input>
          <!--          <el-input  size="small" type="text" placeholder="请选择负责人"></el-input>-->
          <el-button type="primary" size="small" @click="getList">查询</el-button>
        </div>
      </div>
      <div class="list">
        <el-tabs v-model="carState" @tab-click="handleClick">
          <el-tab-pane label="空闲中" name="1"></el-tab-pane>
          <el-tab-pane label="出租中" name="2"></el-tab-pane>
          <el-tab-pane label="已出租" name="3"></el-tab-pane>
        </el-tabs>
        <el-table
            :data="tableData"
            style="width: 100%">
          <el-table-column
              fixed
              type="index"
              label="序号"
              width="80">
          </el-table-column>
          <el-table-column
              prop="carNum"
              label="车牌号"
              width="300">
          </el-table-column>
          <el-table-column
              prop="carBrand"
              label="品牌"
              min-width="120">
          </el-table-column>
          <el-table-column
              prop="carMode"
              label="型号"
              min-width="120">
          </el-table-column>
          <el-table-column
              prop="freeTime"
              label="空闲时间"
              min-width="120" v-if="carState==0">
          </el-table-column>
          <el-table-column
              prop="is_sell"
              label="车辆状态"
              min-width="80">
            <template slot-scope="scope">
              <span v-if="scope.row.type===1" class="red">空闲中</span>
              <span v-if="scope.row.type===2" class="red">待出租</span>
              <span v-if="scope.row.type===3" class="red">已出租</span>
            </template>
          </el-table-column>
          <el-table-column
              fixed="right"
              label="操作"
              width="200">
            <template slot-scope="scope">
              <el-button
                  v-if="showRouter('admin')"
                  @click="handleCatCar(scope.row)" type="text"
                  size="small"
              >查看
              </el-button>
              <el-button
                  type="text" size="small"
                  @click="handleDelCar(scope.row)"
                  v-if="scope.row.type===4 || scope.row.type===1 && showRouter('admin')"
              >删除
              </el-button>
              <el-button
                  type="text"
                  size="small"
                  @click="HandleCarCel(scope.row)"
                  v-if="scope.row.type===2 && showRouter('admin')"
              >取消出租
              </el-button>
            </template>
          </el-table-column>
        </el-table>

      </div>
      <div class="pages">
        <el-pagination
            background
            :hide-on-single-page="showPage"
            @current-change="handleCurrentChange"
            layout="prev, pager, next"
            :page-size="pageSize"
            :total="total">
        </el-pagination>
      </div>
    </el-row>
    <CatCar ref="catcar"></CatCar>
    <car-house ref="house"></car-house>
  </div>
</template>

<script>
import CatCar from "@/components/CatCar";
import CarHouse from "@/components/CarHouse";
import {delOne, downUp, getList} from "@/utils/api/car";

export default {
  name: "zc",
  components:{
    CatCar,
    CarHouse
  },
  data() {
    return {
      total: 0, //总条数
      pageNo: 1,
      pageSize: 10,
      showPage: true,
      carState: 0,
      tableData: [], //表格数据
      plate: "",//搜索车牌
      head: "",//负责人
      editOne: '', //编辑数据,
      keywords: "" //搜索关键字
    }
  },
  methods: {
    home(){
      this.goHome()
    },
    showRouter(permission) {
      if (permission === "admin") {
        return true;
      } else {
        let result = this.$store.state.permissions.findIndex(
            item => item === permission
        );
        if (result !== -1) {
          return true;
        } else {
          return false;
        }
      }
    },
    handleCatCar(row){
      this.$router.push({name:'租车详情',params: {id:row.id,state:this.carState}})
    },
    handleCurrentChange(val) {
      this.pageNo = val;
      this.getList()
    },
    handleClick(e) {
      this.getList()
    },
    //获取车辆出售列表
    getList() {
      let data = {
        type: this.carState,
        rows: this.pageSize,
        page: this.pageNo,
      };
      if (this.keywords !== "") {
        data.carNum = this.keywords;
      }
      getList(data).then(res => {
        if (res.code === 0) {
          this.tableData = res.data.list;
          this.total = Number(res.data.total)
        }
      });
    },
    //删除车辆
    handleDelCar(row) {
      this.$confirm('此操作将删除该车辆, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // console.log(row)
        let data = {
          // type: "1",
          id: row.id
        }
        delOne(data).then(res => {
          if (res.code === 0) {
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
            this.getList()
          } else {
            this.$message({
              type: 'error',
              message: res.msg
            });
          }
        })
      }).catch(() => {
        // this.$message({
        //   type: 'info',
        //   message: '已取消删除'
        // });
      });
    },
    //车辆取消出租
    HandleCarCel(row) {
      this.$confirm('此操作将取消下架该车辆, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let data = {
          type: "1",
          id: row.id
        }
        downUp(data).then(res => {
          if (res.code === 0) {
            this.getList()
            this.$message({
              type: 'success',
              message: '取消出租成功!'
            });

          } else {
            this.$message({
              type: 'error',
              message: res.msg
            });
          }
        })
      }).catch(() => {
      });
    },
  },
  created() {
    let carState = this.$route.query.carState
    this.carState = carState ? String(carState) : "1"
    this.getList()
  }
}
</script>

<style  lang="less">
.table-body {
  background-color: #FFFFFF;
  padding: 30px;
  padding-top: 0;
  .line {
    height: 90px;
    line-height: 90px;
    .title {
      font-size: 16px;
      font-weight: 800;
      color: #333333;
    }
    .search {
      display: flex;
      align-items: center;
      align-content: center;
      justify-items: flex-end;
      .el-input {
        font-size: 12px;
        margin-right: 12px;
        .el-input__inner {
          border: none;
          background-color: #FAFAFA;
          padding-left: 50px;
        }

        .el-input__prefix {
          width: 40px;
          &:after {
            content: '';
            width: 1px;
            height: 15px;
            background-color: #D8D7D7;
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
          }
        }
      }
    }
  }
}

</style>
