<template>
  <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      width="80%"
  >
    <el-form ref="form" :model="form" label-position="right" class="info">
      <el-row :gutter="20">
        <el-col :xs="24" :sm="24" :md="12" :lg="6" :xl="6">
          <el-form-item label="车牌号" :label-width="formLabelWidth" >
            <el-input v-model="form.carNum" autocomplete="off" placeholder="未设置" :disabled="disable"></el-input>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="24" :md="12" :lg="6" :xl="6">
          <el-form-item label="品牌型号" :label-width="formLabelWidth" >
            <el-input v-model="form.carBrand + '/' + form.carMode" autocomplete="off" placeholder="品牌" :disabled="disable"></el-input>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="24" :md="12" :lg="6" :xl="6">
          <el-form-item label="车架号" :label-width="formLabelWidth" >
            <el-input v-model="form.vinNo" autocomplete="off" placeholder="未设置" :disabled="disable"></el-input>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="24" :md="12" :lg="6" :xl="6">
          <el-form-item label="发动机号" :label-width="formLabelWidth" >
            <el-input v-model="form.engineNo" autocomplete="off" placeholder="未设置" :disabled="disable"></el-input>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="24" :md="12" :lg="6" :xl="6">
          <el-form-item label="注册日期" :label-width="formLabelWidth" >
            <el-input v-model="form.registerTime" autocomplete="off" placeholder="未设置" :disabled="disable" ></el-input>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="24" :md="12" :lg="6" :xl="6">
          <el-form-item label="车辆类型" :label-width="formLabelWidth" >
            <el-input v-model="form.leaseCarType" autocomplete="off" placeholder="未设置" :disabled="disable"></el-input>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="24" :md="12" :lg="6" :xl="6">
          <el-form-item label="运行平台" :label-width="formLabelWidth" prop="operPlatform">
            <el-input v-model="form.operPlatform" autocomplete="off" placeholder="未设置" :disabled="disable"></el-input>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="24" :md="12" :lg="6" :xl="6">
          <el-form-item label="公里数" :label-width="formLabelWidth" prop="kilometers">
            <el-input v-model="form.kilometers" autocomplete="off" placeholder="未设置" maxlength="7" :disabled="disable"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <el-form ref="form" :model="form" label-position="right" class="info">
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="年检到期时间" :label-width="formLabelWidth" prop="remark">
            <el-input v-model="form.yearCheckUpTimeScope" autocomplete="off" placeholder="未设置" maxlength="6" :disabled="disable">
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="车险到期时间" :label-width="formLabelWidth" prop="remark">
            <el-input v-model="form.insureTime" autocomplete="off" placeholder="未设置" maxlength="6" :disabled="disable">
            </el-input>
          </el-form-item>
        </el-col>

      </el-row>
    </el-form>
    <el-form ref="form" :model="form" label-position="right" class="info">
      <el-row>

        <el-col :xs="24" :sm="12" :md="8" :lg="3" :xl="3">
          <el-form-item label="支付方式" :label-width="formLabelWidth" prop="hireMode" >
            <el-radio-group v-model="form.hireMode" >
              <el-radio label="周租" v-if="form.hireMode=='周租'">周租</el-radio>
              <el-radio label="月租" v-if="form.hireMode=='月租'">月租</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="24" :md="12" :lg="6" :xl="6">
          <el-form-item label="租期" :label-width="formLabelWidth" prop="remark">
            <el-input v-model="form.hireTimeType" autocomplete="off" placeholder="未设置" maxlength="6" :disabled="disable">
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="24" :md="12" :lg="6" :xl="6">
          <el-form-item label="租金" :label-width="formLabelWidth" prop="hireMoney">
            <el-input v-model="form.hireMoney" autocomplete="off" placeholder="未设置" maxlength="6" :disabled="disable">
              <span slot="append">元</span>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="24" :md="12" :lg="6" :xl="6">
          <el-form-item label="押金" :label-width="formLabelWidth" prop="depositMoney">
            <el-input v-model="form.depositMoney" autocomplete="off" placeholder="未设置" maxlength="6" :disabled="disable">
              <span slot="append">元</span>
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <el-form ref="form" :model="form" label-position="right" class="info">
      <el-row :gutter="20">

        <el-col :span="8">
          <el-form-item label="联系人" :label-width="formLabelWidth" prop="carNum">
            <el-input v-model="form.liaisonName" autocomplete="off" placeholder="未设置" :disabled="disable"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="联系电话" :label-width="formLabelWidth" prop="liaisonPhone">
            <el-input v-model="form.liaisonPhone" autocomplete="off" placeholder="未设置" :disabled="disable"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="备注" :label-width="formLabelWidth" prop="remark">
            <el-input v-model="form.remark" autocomplete="off" placeholder="未设置" :disabled="disable"
                      type="textarea"
                      rows="6"
                      maxlength="200"
                      show-word-limit
                      resize="none"
            >
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>

  </el-dialog>
</template>

<script>
import {getStore} from "@/utils/storage";
import {detailOne } from '@/utils/api/car'
import {getCList} from "@/utils/api/contact";
import {getDict, getResponsible} from "@/utils/api/dict";
import helper from "@/utils/helper";
export default {
  name: "CatCar",
  props: {},
  data() {
    return {
      dialogVisible: false,
      title: '',
      form: {},
      staticBase: '',
      disable: false,
      formLabelWidth: "100px",
      labelPosition: "right",
      cList:[],//联系人列表
      hList:[],//负责人列表
      pList:[]
    }
  },
  methods: {
    showCar(row) {

      if (row) {
        this.title = '查看车辆信息'
        // this.form = Object.assign(this.form, row, {})
        this.getDetail(row.id)
      }
    },
    catCar(row){
      if(row){
        this.title = '查看出租中信息'
        this.getDetail(row.id)
        this.disable = true

      }
    },
    getDetail(id){
      let  form = {
        id: id
      }
      detailOne(form).then(res => {
        if(res.code == 0){
          this.form =this.form = Object.assign(this.form, res.data.carInfo, {})
          this.form.registerTime= helper.dealTime(this.form.registerTime)
          this.form.yearCheckUpTimeScope= helper.dealTime(this.form.yearCheckUpTimeScope)
          this.form.insureTime= helper.dealTime(this.form.insureTime)
          this.dialogVisible = true
        }
      })
    },
    //获取联系人
    getContactList(){
      // this.cList
      getCList().then(res => {
        if(res.code ===0 ){
          this.cList =  res.data
        }
      })
    },
    //获取负责人
    getResponsible(){
      getResponsible().then(res => {
        if(res.code === 0){
          this.hList = res.data
        }
      })
    },
    //获取运行平台
    getDict() {
      let data = {
        code: 'YXPT'
      }
      getDict(data).then(res => {
        if (res.code === 0) {
          this.pList = res.data
        }
      })
    },
  },

  created() {
    this.staticBase = getStore('imgBase');
    this.getContactList()
    this.getResponsible()
    this.getDict()
  }
}
</script>

<style  lang="less" scoped>


.el-dialog__body {
.el-input {
  width: 90%;
}
}

.el-select {
  width: 100%;
}

.s-radio {
  margin-top: 30px;
}

</style>
