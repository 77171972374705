<template>
  <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      width="45%"
  >
    <div>
      <div class="img clearfix">
        <div class="img-item">
          <div class="img-item-title"><span style="color: red">*</span>行驶证正面</div>
          <el-image
              style="width: 10vw; height: 120px"
              :src="staticBase + form.vehicleLicenseZUrl"
          ></el-image>
        </div>
        <div class="img-item">
          <div class="img-item-title"><span style="color: red">*</span>行驶证反面</div>
          <el-image
              style="width: 10vw; height: 120px"
              :src="staticBase + form.vehicleLicenseFUrl"
          ></el-image>
        </div>
        <div class="img-item">
          <div class="img-item-title"><span style="color: red">*</span>车辆45º照片</div>
          <el-image
              style="width: 10vw; height: 120px"
              :src="staticBase + form.car45Url"
          ></el-image>
        </div>
      </div>
      <div class="info1">
        <div class="info-item">
          <div class="info-item-title"><span style="color: red">*</span>车牌号</div>
          <div class="info-item-input" ><el-input  :value="form.carNum" :disabled="disable"></el-input></div>
        </div>
      </div>
      <div class="info1">
        <div class="info-item">
          <div class="info-item-title"><span style="color: red">*</span>品牌</div>
          <div class="info-item-input" ><el-input  :value="form.carBrand + '/' + form.carMode" :disabled="disable"></el-input></div>
        </div>
      </div>
      <div class="info1">
        <div class="info-item">
          <div class="info-item-title"><span style="color: red">*</span>车架号</div>
          <div class="info-item-input" ><el-input  :value="form.vinNo" :disabled="disable"></el-input></div>
        </div>
      </div>
      <div class="info1">
        <div class="info-item">
          <div class="info-item-title"><span style="color: red">*</span>发动机号</div>
          <div class="info-item-input" ><el-input   :value="form.engineNo" :disabled="disable"></el-input></div>
        </div>
      </div>
      <div class="info1">
        <div class="info-item">
          <div class="info-item-title"><span style="color: red">*</span>注册日期</div>
          <div class="info-item-input" ><el-input :value="form.registerTime" :disabled="disable"></el-input></div>
        </div>
      </div>
      <div class="info1">
        <div class="info-item">
          <div class="info-item-title"><span style="color: red">*</span>车辆类型</div>
          <div class="info-item-input" ><el-input :value="form.leaseCarType" :disabled="disable"></el-input></div>
        </div>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
    <el-button @click="dialogVisible = false">取 消</el-button>
    <el-button type="primary" @click="dialogVisible = false" v-if="disable">确 定</el-button>
  </span>
  </el-dialog>
</template>

<script>
import {getStore} from "@/utils/storage";
import {detailOne } from '@/utils/api/car'
export default {
  name: "CarHouse",
  props: {},
  data() {
    return {
      dialogVisible: false,
      title: '',
      form: {},
      staticBase: '',
      disable: false
    }
  },
  methods: {
    showCar(row) {

      if (row) {
        this.title = '查看车辆信息'
        // this.form = Object.assign(this.form, row, {})
        this.getDetail(row.id)
      }

    },
    catCar(row){
      if(row){
        this.title = '查看车辆信息'
        this.getDetail(row.id)

      }
    },
    getDetail(id){
      let  data = {
        id: id
      }
      detailOne(data).then(res => {
        if(res.code === 0){
          this.form =this.form = Object.assign(this.form, res.data.carInfo, {})
          this.dialogVisible = true
        }
      })
    }
  },
  created() {
    this.staticBase = getStore('imgBase');

  }
}
</script>

<style >
/*.img {*/
/*  display: flex;*/
/*}*/
.img .img-item {
  float: left;
  width: 33%;
  min-height: 90px;
}
.img .img-item .img-item-title {
  margin-bottom: 1vh;
}
.info1 {
  margin-left: 50px;
}
.info-item {
  display: flex;
  padding: 10px 10px;
}
.info-item-title {
  font-size: 14px;
  text-align: right;
  width: 10%;
  margin-right: 10px;
  margin-top: 1.2%;
}
.info-item-input{
  width: 55%;
}


</style>
